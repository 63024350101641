import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import './icons';

@Component({
  selector: 'app-icon',
  template: '<iconify-icon [style]="getStyle()" height="{{ height }}" icon="{{ icon }}"></iconify-icon>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input()
  public height?: string;

  @Input()
  public icon!: string;

  @Input() inline: boolean | '' = false;

  public ngOnInit() {
    this.inline = !(this.inline === false);
  }

  protected getStyle() {
    return {
      display: this.inline ? 'inline-block' : 'inline',
      'vertical-align': this.inline ? 'bottom' : undefined,
    };
  }
}
